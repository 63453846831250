.timerCustomComponent {
  display: flex;
  justify-content: center;
  font-size: 32px;
  color: white;
}

.splitter {
  margin: 0 16px;
  color: white;
  line-height: 70px;
}

.timeBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  color: white;
  padding: 4px;
  border-radius: 16px;
  transition: 0.2s;

  &:focus,
  &:hover {
    filter: brightness(118%);
    transform: scale(1.02);
  }

  & small {
    font-size: 12px;
    font-weight: normal;
    color: white;
  }
}

.suffix {
  line-height: 80px;
  font-size: 40px;
  height: 0;
}
